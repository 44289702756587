
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mh {
	min-height:500px;
}
.mw {
	max-width:1366px;
}
.mw2 {
   max-width:990px;
}

.foots {
	z-index: 100;
	background-color: black;
	color: black;
	width: 100%;
	clear: both;
	text-align: center;
	font-size: 0.90rem;
	padding-top: 3px;
}

.foot {
	text-align: center;
	margin: auto;
}

.fo1 {
	margin-top:0.3rem;
	color: gray;
	grid-area: "fo1";
}
.fo2 {
	margin-top:0.3rem;
	grid-area: "fo2";
}
.fo3 {
	font-weight: bold;
	color: white;
	font-size: 1.2rem;
	margin-top: 0.5rem;
	grid-area: "fo3";
}

.gf {
	display:grid;
	grid-template-areas:
	"fo1"
	"fo2"
	"fo3";
}

.heads {
	color:white;
	background-color: black;
	width: 100%;
	clear:both;
	text-align: center;
}

.head {
	text-align: center;
	margin: auto;
	padding-top: 20px;
	padding-bottom: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

